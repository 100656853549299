import React from 'react';
import './App.css';
import Navigation from './Navigation/Navigation';
import AboutUs from './AboutUs/AboutUs';
import Parallax from './Parallax/Parallax';
import Events from './Events/Events';
import Offer from './Offer/Offer';
import Contact from './Contact/Contact';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <AboutUs/>
      <Parallax number={1}/>
      <Events/>
      <Parallax number={2}/>
      <Offer/>
      <Parallax number={3}/>
      <Contact/>
    </div>
  );
}

export default App;
