import React from "react";
import './Aboutus.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const AboutUs = () => {
    return (
        
        <div id="aboutUs" className="AboutUs"><ScrollAnimation animateIn="animate__backInRight" animateOut="animate__backOutLeft" duration={0.7}>
                        <b>Firma Mobilito powstała w&nbsp;2015 roku.</b><br/><br/> 
Filarem naszej działalności jest  dystrybucja oraz sprzedaż doskonałej jakości olejów. Oprócz olejów oferujemy również szeroką gamę
specjalistycznych produktów smarnych i&nbsp;chłodziw dla przemysłu i&nbsp;motoryzacji. Dziękizaufaniu naszych klientów poszerzyliśmy asortyment
o&nbsp;części samochodowe oraz akcesoria i&nbsp;kosmetyki  do samochodów, w&nbsp;wyniku czego możemy kompleksowo obsłużyć warsztaty mechaniczne
, firmy transportowe, stacje paliw itp.
<br/><br/> 
Zdecydowaliśmy się również na wzbogacenie oferty o&nbsp;sprzedaż i&nbsp;serwis silników elektrycznych, sprzęgieł, przekładni, motoreduktorów
oraz łożysk przemysłowych.<br/><br/> 
Dzięki temu posunięciu pojawiły się nowe rynki zbytu, zarówno krajowe jak i&nbsp;zagraniczne, między innymi: tartaki, firmy produkcyjne,
suszarnie, myjnie samochodowe itp.<br/><br/> 
Nasza działalność jest podparta wieloletnim doświadczeniem w&nbsp;branży, które to chcemy wykorzystać, aby sukcesywnie budować ugruntowaną
pozycję na rynku stale wzbogacając ofertę o&nbsp;nowe produkty.<br/><br/> 
Zadowolenie naszych Klientów jest dla nas sprawą najważniejszą, dlatego uważnie wsłuchujemy się w&nbsp;pytania i&nbsp;sugestie od klientów.
Istniejemy dzięki Klientom i&nbsp;jesteśmy dla nich.<br/><br/>  
Posiadamy własną flotę pojazdów zapewniającą elastyczność dostaw, a&nbsp;także umożliwiamy odbiór bezpośredni z&nbsp;naszego magazynu. 
Sprzedaż detaliczna i&nbsp;hurtowa jest prowadzona w&nbsp;Międzyrzeczu Górnym.<br/><br/> 
Dokładamy wszelkich starań, aby zamówienia były realizowane błyskawicznie i&nbsp;terminowo, a&nbsp;oferowany towar był najwyższej jakości.  
</ScrollAnimation>
        </div>
        
    )
}

export default AboutUs