import React from "react";
import './events.css'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Events = () => {
    return (
        <div className="Events"><ScrollAnimation animateIn="animate__backInLeft" animateOut="animate__backOutRight" duration={0.7}>
            <h1>Wydarzenia</h1>
            <div>
<b>2015 r.</b> - Założenie firmy<br/> <br/>
<b>2016 r.</b> – Rozpoczęcie sprzedaży wysyłkowej poprzez sklep internetowy<br/> <br/>
<b>2017 r.</b> - Ciągły rozwój firmy powoduje zwiększenie powierzchni biurowych i&nbsp;magazynowych, dzięki którym możemy sukcesywnie zwiększać
sprzedaż <br/> <br/>
<b>2017 r.</b> – Podnosimy swoje kwalifikacje uzyskując certyfikat udziału w&nbsp;szkoleniu firmy „FILTRON”<br/> <br/>
<b>2017 r.</b> – Usługi grawerskie – rozszerzenie zakresu działalności firmy, zakup plotera laserowego, usługa cięcia i&nbsp;grawerowania (sklejka,
szkło, aluminium, itp…)<br/> <br/>
<b>2017 r.</b> – rozpoczęcie współpracy w&nbsp;zakresie dystrybucji towarów firmy MANNOL<br/> <br/>
<b>2018 r.</b> - MOBILITO zostaje Partnerem Kobiecej Drużyny Siatkarek '' BKS Profi Credit Bielsko-Biała ''<br/> <br/>
<b>2020 r.</b> – Działalność usługowa dotycząca wsparcia firm w&nbsp;zakresie utrzymania ruchu – obsługa eksploatacyjno-remontowa maszyn
i&nbsp;urządzeń<br/> <br/> 
</div>
</ScrollAnimation></div>
    )
}

export default Events