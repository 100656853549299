import React from "react";
import './navigation.css';
import Slideshow from "./Slider";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Navigation = ()=> {

    return(
        <div className="Navigation">
            <div className="navigation_header">
                    <div><a href="https://www.facebook.com/Mobilito.eu"><FacebookIcon/></a></div>
                    <div><a href="https://www.instagram.com/mobilito.eu/"><InstagramIcon/></a></div>
                    <div><a href="tel:502-754-760"><LocalPhoneIcon/></a></div>
            </div>
            <nav>
                <div>
                    <a href="#aboutUs">O nas</a>
                    <div className="underline"></div>
                </div>
                <div>
                    <a href="#offer">Oferta</a>
                    <div className="underline"></div>
                    </div>
                <div>
                    <a href="#contact">Kontakt</a>
                    <div className="underline"></div>
                </div>
                <div>
                    <a href="https://sklep.mobilito.eu" target="_blank">Sklep</a>
                    <div className="underline"></div>
                </div>
            </nav>
            <div className="logoAndMotto">
            <img className="logo" alt="logo" src="/logo.png"/>
            <div className="motto">"Zadania niemożliwe realizujemy od ręki , cuda zajmują nam trochę czasu."</div>
            </div>
            <Slideshow/>
        </div>
    )
}

export default Navigation