import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './contact.css'

const Contact = () => {
    return (
        <div id="contact" className="Contact">
            <div className="ContactSections">
            <ScrollAnimation animateIn="animate__backInLeft" animateOut="animate__backOutLeft" duration={0.7}>
            <div className="ContactFirstSection">
            <b>KONTAKT</b><br/>
Wszystkie nasze oddziały są czynne:<br/>
Pn-Pt - 8.00-16.00, sob. - nieczynne<br/>
Zapraszamy!
<br/><br/>
Mobilito - adres biura i magazynu<br/>
43-392 Międzyrzecze Górne 370

            </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__backInRight" animateOut="animate__backOutright" duration={0.7}>
            <div className="ContactSecondSection">
            <b>Dział Obsługi Klienta</b><br/>
Silniki elektryczne , łożyska<br/>
502-754-760<br/>
handlowy@mobilito.eu<br/>
<br/><br/>
Oleje , smary, chemia samochodowa <br/>
508-125-317<br/>
sprzedaz@mobilito.eu

            </div>
            </ScrollAnimation>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2573.5578439768437!2d18.960910851646087!3d49.831974979293726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716a1d00f245bf3%3A0xd450e91f5d16bf93!2sMOBILITO!5e0!3m2!1spl!2spl!4v1680120788122!5m2!1spl!2spl" title="map" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div className="footer"><b>Mobilito.EU © Wszystkie prawa zastrzeżone | Designed & Developed by <a href="https://webdevelopment.hype-street.pl">Hype Street WebDevelopment</a></b></div>
        </div>
    )
}

export default Contact