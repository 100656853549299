import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Slideshow = () => {
    const images = [
        "./slider/slide_1.jpg",
        "./slider/slide_2.jpg",
        "./slider/slide_3.jpg",
    ];

    const divStyle = {
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    filter: 'brightness(50%)'
    }

    return (
        <Slide>
        {images.map((images, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${images})` }}>
              </div>
            </div>
          ))}
    </Slide>
    );
};

export default Slideshow;