import React from "react";
import './offer.css'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Offer = () => {
    return (
        <div id="offer" className="Offer">
            <h1>Oferta:</h1>
            <ul className="ulNav">
                <a href="#10"><li>Silniki elektryczne</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#1"><li>Oleje silnikowe do samochodów osobowych, dostawczych i ciężarowych</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#2"><li>Oleje dedykowane OEM do samochodów marek: FORD, MAZDA, OPEL, CITROEN, PEUGEOT, VOLVO, HYUNDAI, KIA, LEXUS, TOYOTA, CHEVROLET, RENAULT, NISSAN, VW, AUDI, SKODA</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#3"><li>Oleje dla motocykli i quadów</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#4"><li>Oleje hydrauliczne i przemysłowe</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#5"><li>Oleje do prowadnic</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#6"><li>Smary</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#7"><li>Oleje dla przemysłu rolnego</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#8"><li>Oleje dla ogrodnictwa</li></a>
            </ul>
            <ul className="ulNav">
                <a href="#9"><li>Oleje Przekładniowe</li></a>
            </ul>
            <br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="10">
<b>Silniki elektryczne:</b><br/>
<div className="offerContent">
<div className="offerImages">
    <img className="offerImageEngine" alt="offer" src='/offer/21.png'/>
    <img className="offerImageEngine" alt="offer" src='/offer/20.png'/><br/>
    <img className="offerImageEngine" alt="offer" src='/offer/19.png'/>
    <img className="offerImageEngine" alt="offer" src='/offer/2.png'/>
</div>
<div className="offerText">

<a className="shop" href="https://sklep.mobilito.eu" target="_blank">
sprzedaż i serwis silników elektrycznych
<ul>
    <li>WAT </li>
    <li>SIEMENS</li>
    <li>CELMA INDUKTA</li>
    <li>ANIKOM</li>
</ul>
<br/>
<ShoppingCartIcon  sx={{ fontSize: 100 }}/>
</a>

</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
            <ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="1">
<b>Oleje silnikowe do samochodów osobowych, dostawczych i ciężarowych:</b>
<div className="offerContent">
<div className="offerText">
<ul>
<li>MANNOL</li>
<li>MOBIL</li> 
<li>ELF</li> 
<li>SHELL</li>
<li>CASTROL</li>
<li>ORLEN</li>
<li>LOTOS</li>
<li>RAVENOL</li>
<li>JASOL</li>
</ul>
</div>
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/2.jpg'/>
    <img className="offerImage" alt="offer" src='/offer/1.jpg'/>
</div>
</div>
</div>            
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="2">
<b>Oleje dedykowane OEM  
do samochodów marek:</b><br/>
<div className="offerContent">
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/3.jpg'/>
    <img className="offerImage" alt="offer" src='/offer/4.jpg'/>
</div>
<div className="offerText">
FORD, MAZDA, OPEL, CITROEN, PEUGEOT,<br/>
VOLVO, HYUNDAI, KIA, LEXUS, TOYOTA,<br/>
CHEVROLET, RENAULT, NISSAN,<br/>
VW, AUDI, SKODA
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="3">
<b>Oleje dla motocykli i quadów:</b><br/>
<div className="offerContent">
<div className="offerText">
2-TAKT  PLUS 10w-40<br/>
2-TAKT UNI 15w-40 <br/>
4-TAKT 10w-40<br/>
4-TAKT RACING QUAD  10w-40
</div>
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/5.jpg'/>
    <img className="offerImage" alt="offer" src='/offer/6.jpg'/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="4">
<b>Oleje hydrauliczne i przemysłowe:</b><br/>
<div className="offerContent">
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/7.png'/>
    <img className="offerImage" alt="offer" src='/offer/8.png'/>
</div>
<div className="offerText">
HYDRAULICZNE HL - 32, 46, 68, 100,<br/>
HYDRAULICZNE HLP - 22, 32, 46, 68,<br/>
 HYDRAULICZNE HLP-D - 32, 46, 68,<br/>
HYDRAULICZNE HV - 22, 32, 46, 68,<br/>
 MASZYNOWE LAN - 46, 68,<br/>
OLEJE SHC<br/>
EMULSJE OBRÓBCZE <br/>
OLEJE DO KOMPRESORÓW
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="5">
<b>Oleje do prowadnic:</b><br/> 
<div className="offerContent">
<div className="offerText">
MOBIL<br/>
SHELL<br/>
JASOL<br/>
</div>
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/13.png'/>
    <img className="offerImage" alt="offer" src='/offer/14.png'/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="6">
<b>Smary:</b><br/>
<div className="offerContent">
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/11.png'/>
    <img className="offerImage" alt="offer" src='/offer/12.png'/>
</div>
<div className="offerText">
GRAFITOWANE<br/>
STP<br/>
ŁT EP-2<br/>
ŁT 43<br/>
SMARY CENTRALNEGO SMAROWANIA<br/>
SMARY Z DWUSIARCZKIEM MOLIBDENU<br/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="7">
<b>Oleje dla przemysłu rolnego:</b><br/>
<div className="offerContent">
<div className="offerText">
OLEJ  UTTO 10W30<br/>
OLEJ  UTTO 10W40<br/>
OLEJ  STOU 10W30<br/>
OLEJ  STOU 10W40<br/>
</div>
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/9.png'/>
    <img className="offerImage" alt="offer" src='/offer/10.png'/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="8">
<b>Oleje dla ogrodnictwa:</b><br/>
<div className="offerContent">
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/15.png'/>
    <img className="offerImage" alt="offer" src='/offer/16.png'/>
</div>
<div className="offerText">
OLEJ MIESZANKI PALIWA 2T<br/>
DO SMAROWANIA ŁAŃCUCHA I PROWADNIC<br/>
DO KOSIAREK I PODKASZAREK<br/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__bounceInUp" duration={1.5}>
<div className="offerSection" id="9">
<b>Oleje Przekładniowe:</b><br/>
<div className="offerContent">
<div className="offerText">
75W90, 75W85, 80W90, 80W, 85W140, LHM , LDS, 90LS<br/>
</div>
<div className="offerImages">
    <img className="offerImage" alt="offer" src='/offer/17.png'/>
    <img className="offerImage" alt="offer" src='/offer/18.png'/>
</div>
</div>
</div>
</ScrollAnimation>
<br/><br/><br/>
<ScrollAnimation animateIn="animate__bounceInUp" animateOut="animate__rubberBand" duration={1.5}>
<a className="mail" href="mailto:sprzedaz@mobilito.eu"><div className="mailbox">
    <h1>Masz pytanie?</h1>
    <MailOutlineIcon sx={{fontSize:140}}/>
</div></a>
</ScrollAnimation>
    </div>
    )
}

export default Offer