import React from "react";
import './parallax.css';

const Parallax = ({number}) => {

    const divStyle = {
        height: '20rem',
        width: '100%',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        filter: 'brightness(50%)'
    }

    return(
        <div style={{...divStyle, 'backgroundImage': `url(./parallax/${number}.jpg`}} className="Parallax">
        </div>
    )
}

export default Parallax
